import React from 'react'
import { usePORequests } from '../../hooks'
import { ConfirmationDialog, ThemeButton, useModalContext } from '../../../../ui'
import { Divider, Menu, MenuItem } from '@material-ui/core'
import { useIntl } from 'react-intl'
import { usePORequestsCalls } from '../../hooks/po-requests-calls.hook'
import { parse } from 'qs'
import { DEFAULT_QUERY } from '../table/po-requests-table.constants'
import { useLocation } from 'react-router-dom'
import { PO_REQUEST_STATES } from '../../constants'
import { CancelRequestDialog } from './cancel-request-dialog.component'
import { RejectRequestDialog } from './reject-request-dialog.component'
import { SendMessageDialog } from './send-message-dialog.component'
import { AddSAPCodeDialog } from './add-sap-code-dialog.component'
import { AddsZeusRegisterCodeDialog } from './add-zeus-reg-code-dialog.component'
import { AddsZeusAdhesionCodeDialog } from './add-zeus-adhesion-code-dialog.component'
import feedback from '../../../../core/feedback'


export const PORequestTableActionsDialog = () => {
  const { formatMessage: fm } = useIntl()

  const {
    actions: { open },
  } = useModalContext()

  const location = useLocation()


  const [anchorEl, setAnchorEl] = React.useState(null)

  const { putAdhesionesAccionMasiva } = usePORequestsCalls()
  const { getTableData, setSelectedTableRows, tableSelection, sendMessage } = usePORequests()
  const { postSendMessageCall } = usePORequestsCalls()
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const refreshTableData = () => {
    const searchString = location.search.split('?')[1]
    if (searchString) {
      const urlSearchParams = parse(searchString)
      const searchParams = {
        ...DEFAULT_QUERY,
        ...urlSearchParams,
      }
      getTableData(searchParams)
    }
  }

  const handleOnSubmit = (formData) => new Promise(async (resolve, reject) => {
    const list_adhesiones_id = []
    tableSelection.forEach((peticion) => {
      list_adhesiones_id.push(peticion.id_peticion)
    })

    const payload = { ...formData, list_adhesiones_id: list_adhesiones_id }
    putAdhesionesAccionMasiva(payload).then((r) => {
      refreshTableData()
      setSelectedTableRows([])
      setAnchorEl(null)
      resolve()
    }).catch(() => reject())
  })

  const handleCancelRequest = () => {
    open({
      Component: CancelRequestDialog,
      data: {
        onSubmit: (formData) => new Promise((resolve, reject) => {
          handleOnSubmit({ ...formData, estado: '02' })
            .then(() => {
              resolve()
            })
            .catch(() => {
              reject()
            })
        }),
      },
      maxWidth: 'sm',
    })
  }
  const handleRejectRequest = () => {
    open({
      Component: RejectRequestDialog,
      data: {
        onSubmit: (formData) => new Promise((resolve, reject) => {
          handleOnSubmit({ ...formData, estado: '00' })
            .then(() => {
              resolve()
            })
            .catch(() => {
              reject()
            })
        }),
      },
      maxWidth: 'sm',
    })
  }
  const handleRollbackRequest = () => {
    open({
      Component: ConfirmationDialog,
      data: {
        title: fm({ id: 'pages.poRequests.detail.rollbackRequest.dialog.title' }),
        text: fm({ id: 'pages.poRequests.detail.rollbackRequest.dialog.description' }),
        yesText: fm({ id: 'global.accept' }),
        noText: fm({ id: 'global.cancel' }),
        yesAction: () => {
          handleOnSubmit({ estado: tableSelection[0].estado_anterior })
        },
      },
    })
  }
  const handleSendMessage = () => {
    open({
      Component: SendMessageDialog,
      data: {
        onSubmit: (formData) => new Promise((resolve, reject) => {
          handleOnSubmit({...formData}).then(() => {
            feedback(
              'success',
              fm({ id: 'pages.poRequests.table.actions.feedback.sended' }),
            )
            resolve()
          })
            .catch(() => {
              feedback('error', fm({ id: 'pages.poRequests.table.actions.feedback.notsended' }))
              reject()
            })
        }),
      },
      maxWidth: 'sm',
    })
  }

  const handleSendToSAP = () => {
    open({
      Component: ConfirmationDialog,
      data: {
        title: fm({ id: 'pages.poRequests.detail.sendToSAP.dialog.title' }),
        text: fm(
          { id: 'pages.poRequests.detail.sendToSAP.dialog.description' },
          { email: tableSelection[0].empresa_email || '' },
        ),
        yesText: fm({ id: 'global.accept' }),
        noText: fm({ id: 'global.cancel' }),
        yesAction: () => {
          handleOnSubmit({ estado: '20' })
        },
      },
    })
  }

  const handleAddSAPCode = () => {
    open({
      Component: AddSAPCodeDialog,
      data: {
        cod_sap: '',
        onSubmit: (formData) => new Promise((resolve, reject) => {
          handleOnSubmit({ ...formData, estado: '30' })
            .then(() => {
              resolve()
            })
            .catch(() => {
              reject()
            })
        }),
      },
      maxWidth: 'xs',
    })
  }

  const handleValidateDocs = () => {
    open({
      Component: ConfirmationDialog,
      data: {
        title: fm({ id: 'pages.poRequests.detail.validateDocs.dialog.title' }),
        text: fm({ id: 'pages.poRequests.detail.validateDocs.dialog.description' }),
        yesText: fm({ id: 'global.accept' }),
        noText: fm({ id: 'global.cancel' }),
        yesAction: () => {
          handleOnSubmit({ estado: '40' })
        },
      },
      maxWidth: 'sm',
    })
  }

  const handleAddZeusRegisterCode = () => {
    open({
      Component: AddsZeusRegisterCodeDialog,
      data: {
        onSubmit: (formData) => new Promise((resolve, reject) => {
          handleOnSubmit({ ...formData, estado: '50' })
            .then(() => {
              resolve()
            })
            .catch(() => {
              reject()
            })
        }),
      },
      maxWidth: 'xs',
    })
  }

  const handleAddZeusAdhesionCode = () => {
    open({
      Component: AddsZeusAdhesionCodeDialog,
      data: {
        onSubmit: (formData) => new Promise((resolve, reject) => {
          handleOnSubmit({ ...formData, estado: '60' })
            .then(() => {
              resolve()
            })
            .catch(() => {
              reject()
            })
        }),
      },
      maxWidth: 'xs',
    })
  }

  const handleClearSelection = () => {
    refreshTableData()
    setSelectedTableRows([])
    setAnchorEl(null)
  }


  return (
    <div>
      {tableSelection.length > 0 && (<div>
          <ThemeButton onClick={handleClick} style={{ marginRight: '1em' }} labelM="sm"
                       color="primary">Acciones</ThemeButton>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            style={{ marginTop: '3em' }}
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
          >
            {tableSelection[0].estado !== PO_REQUEST_STATES.CANCELLED &&
              tableSelection[0].estado !== PO_REQUEST_STATES.REJECTED &&
              <>
                <MenuItem
                  onClick={handleCancelRequest}>{fm({ id: 'pages.poRequests.detail.cancelRequest.btn.label' })}
                </MenuItem>
                {tableSelection[0].estado !== PO_REQUEST_STATES.PROCESSED &&
                  (<MenuItem
                    onClick={handleRejectRequest}>{fm({ id: 'pages.poRequests.detail.rejectRequest.btn.label' })}
                  </MenuItem>)
                }
              </>}
            {tableSelection[0].estado === PO_REQUEST_STATES.REJECTED && <><MenuItem
              onClick={handleRollbackRequest}>{fm({ id: 'pages.poRequests.detail.rollbackRequest.btn.label' })}</MenuItem><MenuItem
              onClick={handleSendMessage}>{fm({ id: 'pages.poRequests.detail.sendMessage.btn.label' })}</MenuItem></>}
            {tableSelection[0].estado === PO_REQUEST_STATES.CREATED && <MenuItem
              onClick={handleSendToSAP}>{fm({ id: 'pages.poRequests.detail.sendToSAP.btn.label' })}</MenuItem>}
            {tableSelection[0].estado === PO_REQUEST_STATES.PENDING_SAP && <MenuItem
              onClick={handleAddSAPCode}>{fm({ id: 'pages.poRequests.detail.addSAPCode.btn.label' })}</MenuItem>}
            {tableSelection[0].estado === PO_REQUEST_STATES.PENDING_DOCS_REVIEW && <MenuItem
              onClick={handleValidateDocs}>{fm({ id: 'pages.poRequests.detail.validateDocs.btn.label' })}</MenuItem>}
            {tableSelection[0].estado === PO_REQUEST_STATES.PENDING_ZEUS_REGISTER && <MenuItem
              onClick={handleAddZeusRegisterCode}>{fm({ id: 'pages.poRequests.detail.addZeusRegisterCode.btn.label' })}</MenuItem>}
            {tableSelection[0].estado === PO_REQUEST_STATES.PENDING_ZEUS_ADHESION && <MenuItem
              onClick={handleAddZeusAdhesionCode}>{fm({ id: 'pages.poRequests.detail.addZeusAdhesionCode.btn.label' })}</MenuItem>}
            <Divider />
            <MenuItem
              onClick={handleClearSelection}>{fm({ id: 'pages.poRequests.detail.clearSelection.btn.label' })}</MenuItem>
          </Menu>
        </div>
      )}
    </div>
  )
}