import React, { useEffect, useState } from 'react'
import { Panoramic } from '../../core/maps'

const CENTER_POSITION = {
  lat: 41.9405,
  lng: 2.3163,
}

const DEFAULT_POV = {
  heading: 0,
  pitch: 0,
}

export const PanoramaPicker = ({
  position,
  setPosition,
  center = CENTER_POSITION,
  heading = DEFAULT_POV.heading,
  pitch = DEFAULT_POV.pitch,
  setPov,
}) => {
  const [marker, setMarker] = useState()

  useEffect(() => {
    if (marker) {
      marker.setPosition(position)
    }
  }, [position]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Panoramic
      id="test"
      options={{
        position: center,
        pov: { heading, pitch },
        preference: 'nearest',
        zoomControl: false,
        scrollwheel: false,
        disableDoubleClickZoom: true,
      }}
      onMapLoad={(map) => {
        const gasIcon = {
          url: '/images/pipe_icon.png',
          anchor: new window.google.maps.Point(30, 56),
          scaledSize: new window.google.maps.Size(60, 60),
        }
        const marker = new window.google.maps.Marker({
          position: position || center,
          map,
          draggable: true,
          icon: gasIcon,
        })
        setMarker(marker)
        setPosition(center)
        setPov({ ...map.pov, povLat: map.position.lat(), povLng: map.position.lng() })
        window.google.maps.event.addListener(marker, 'dragend', (e) => {
          setPosition({ lat: e.latLng.lat(), lng: e.latLng.lng(), pixel: e.pixel })
        })
        window.google.maps.event.addListener(map, 'pov_changed', () => {
          setPov({ ...map.pov, povLat: map.position.lat(), povLng: map.position.lng() })
        })
      }}
    />
  )
}

export default PanoramaPicker
