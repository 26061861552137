import React, { useState, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { Box, Typography } from '@material-ui/core'
import { Alert } from '@material-ui/lab'

import { ThemeButton, FormPanel } from '../../../../ui'
import { CLIENT_EDIT_FIELDS, CLIENT_EDIT_CONFIG } from './edit-client-form.constants'
import { getTypeOfDocument, isValidEmail } from '../../../../core/utils'
import { feedback } from '../../../../core/feedback'

export const EditClientForm = ({ close, user, updateClientInfo }) => {
  const intl = useIntl()
  const { formatMessage: fm } = intl
  const combos = useSelector((state) => state.combos)

  const [formState, setFormState] = useState(user)
  const [loading, setLoading] = useState(false)
  const [errorMssg, setErrorMssg] = useState('')

  const handleChange = (e) => {
    e.persist()
    setFormState(x => ({...x, [e.target.name]: e.target.value }))

  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const docType = getTypeOfDocument(formState['identificador'])
    if (!isValidEmail(formState['email'])) {
      return setErrorMssg(fm({ id: 'pages.application.detail.dialogs.editClient.feedback.1' }))
    } else if (formState['tipo_identificador'] === 'NIE' && docType !== 'NIE') {
      return setErrorMssg(fm({ id: 'pages.application.detail.dialogs.editClient.feedback.2' }))
    } else if (formState['tipo_identificador'] === 'NIF' && docType !== 'NIF') {
      return setErrorMssg(fm({ id: 'pages.application.detail.dialogs.editClient.feedback.3' }))
    } else {
      setLoading(true)
      const payload = {
        nombre: formState.nombre,
        apellidos: formState.apellidos,
        tipo_identificador: formState.tipo_identificador,
        identificador: formState.identificador,
        email: formState.email,
        telefono: formState.telefono,
      }
      updateClientInfo(user.id, payload)
        .then(() => {
          feedback('success', fm({ id: 'pages.application.detail.dialogs.editClient.feedback.5' }))
          setLoading(false)
          close()
        })
        .catch((err) => {
          if (err.message === 'User ya existe') {
            feedback('error', fm({ id: 'pages.application.detail.dialogs.editClient.feedback.4' }))
          }
          setLoading(false)
        })
    }
  }

  const fieldList = useMemo(
    () => {
      return CLIENT_EDIT_FIELDS({ combos, intl, onChange: handleChange, user })
    },
    [combos] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const block = useMemo(() => {
    return CLIENT_EDIT_CONFIG({ intl })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <form onSubmit={handleSubmit}>
      {user.oauth2_id ? (
        <Alert severity="warning">{fm({ id: 'pages.application.detail.client.noEdit' })}</Alert>
      ) : null}
      <FormPanel {...{ data: formState, combos, intl, block, fieldList }} />
      {errorMssg && (
        <Box mt={1} bgcolor="grey">
          <Typography variant="body2" color="error">
            {errorMssg}
          </Typography>
        </Box>
      )}
      <Box display="flex" justifyContent="space-around" p={3} flexWrap="wrap">
        <Box width={190} mb={[3, 0]} p="0 5px">
          <ThemeButton onClick={close} variant="outlined" fullWidth disabled={loading}>
            {fm({ id: 'global.cancel' })}
          </ThemeButton>
        </Box>
        <Box width={190} p="0 5px">
          <ThemeButton type="submit" color="primary" fullWidth loading={loading} disabled={loading || JSON.stringify(formState) === JSON.stringify(user)}>
            {fm({ id: 'global.save' })}
          </ThemeButton>
        </Box>
      </Box>
    </form>
  )
}
